import React from "react";
import Layout from "../components/layout";

import * as styles from "../styles/contact.module.css";

function contact() {
  return (
    <Layout pageTitle="Contact">
      <div className={`${styles.contactHeader} pageHeaderBar`}>Contact</div>
      <div className={styles.contactContainer}>Coming soon.</div>
    </Layout>
  );
}

export default contact;
